.firstpage-mobile {
    height: 800px;
    padding-right: 1rem;
    padding-left: 1rem;
}

.firstpage-mobile h1 {
    margin-top: 7rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    text-align: center;
    color: #000000;
}

.firstpage-mobile p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000000;
    text-align: center;
}

.solution-mobile {
    background-color: #503bda;
    height: 800px;
    border-radius: 110px 0px;
    padding-right: 1rem;
    padding-left: 1rem;
}

.solution-mobile h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 47px;
    text-align: center;
    color: #ffffff;
    padding-right: 2rem;
    padding-left: 2rem;
}

.solution-page-sub-mobile {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
}

.solution-page-sub-2-mobile {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: #ffffff;
}

.solution-card-mobile {
    width: 100%;
    height: 66px;
    background: linear-gradient(90deg, #59c8f7 0%, #abf9a7 100%);
    border-radius: 13px;
}

.solution-card-mobile h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: white;
    margin: 0;
}

.solution-card-mobile p {
    margin: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: white;
}

.solution-card-mobile span {
    font-size: 44px;
}

.advice-mobile {
    padding-right: 1rem;
    padding-left: 1rem;
    height: 800px;
}

.advice-mobile h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #000000;
}

.advice-mobile__sub-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.advice-mobile__text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
}

.advice-item-mobile {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
}

.advice-item-mobile span {
    font-size: 32px;
    margin-right: 1rem;
}

.invest-page-mobile {
    height: 800px;
    position: relative;
    padding-right: 1rem;
    padding-left: 1rem;
    background: linear-gradient(197.29deg, #abf9a7 0.32%, #59c8f7 98.23%);
    border-radius: 110px 0px;
}

.invest-page-mobile h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    color: #000000;
    position: relative;
    z-index: 100;
}

.pig-mobile {
    position: absolute;
    top: 38px;
    right: 3px;
}

.buying-mobile {
    height: 800px;
    padding-top: 3rem;
}

.buying-mobile img {
    margin-top: 4rem;
}

.buying-mobile .container {
    padding-right: 1rem;
    padding-left: 1rem;
}

.buying-mobile h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 29px;
    text-align: center;
    color: #000000;
}

.newsletter-mobile {
    padding-right: 1rem;
    padding-left: 1rem;
    height: 50vh;
    background: #503bda;
    border-radius: 110px 0px 0px 0px;
}

.newsletter-mobile img {
    width: 61px;
}

.newsletter-mobile .title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: #ffffff;
}

.newsletter__container-input-mobile {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
}

.newsletter-mobile input {
    text-align: center;
    width: 100%;
    border: 0;
    height: 44px;
    border-radius: 10px;
}

.footer-mobile {
    text-align: center;
    background: #503bda;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 2rem;
}

.footer-mobile span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-decoration-line: underline;
    color: #ffffff;
}

.footer-mobile p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-decoration: none;
    color: #ffffff;
    margin-top: 1rem;
    cursor: pointer;
}
