.homepage {
    background-color: #f6f6f6;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.column {
    flex-direction: column;
}

.row {
    flex-direction: row;
}

html {
    background-color: #f6f6f6;
    height: 100%;
}

/* firstpage */
.firstpage {
    padding-left: 3rem;
    /* text-align: center; */
}

.firstpage h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    letter-spacing: 0.2px;
    color: #000000;
    margin-bottom: 0.5rem;
}

.firstpage p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #000000;
}

.firstpage span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #59c8f7;
    padding-left: 2rem;
    cursor: pointer;
}

/* firstpage */

/* solutio-page */
.solution-page {
    position: relative;
    background: #503bda;
    padding-left: 3rem;
    border-top-left-radius: 350px;
    border-bottom-right-radius: 350px;
    overflow: hidden;
}

.sizing-page {
    max-width: 1440px;
}

.sizing-height {
    height: 850px;
}

.solution-page h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 74px;
    letter-spacing: 1.2px;
    color: #ffffff;
}

.solution-page-sub {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    letter-spacing: 1.2px;
    color: #ffffff;
}

.solution-page-sub-2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 1.2px;
    color: #ffffff;
}

.solution-card {
    background: linear-gradient(90deg, #59c8f7 0%, #abf9a7 100%);
    border-radius: 25px;
    width: 360px;
    height: 200px;
    text-align: center;
    padding: 0.5rem;
}

.solution-card span {
    font-size: 44px;
}

.solution-card h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    margin-top: 0;
    color: #ffffff;
}

.solution-card p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: #ffffff;
    margin-top: 0;
    padding-left: 1rem;
    padding-right: 1rem;
}
/* solutio-page */

/* advice-page */
.advice-page {
    /* height: 100vh; */
    /* padding-right: 8rem; */
    padding-left: 3rem;
    /* position: relative; */
}

.advice-page h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 46px;
    color: #000000;
}

.advice-page__sub-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    color: #000000;
}

.advice-page__text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000000;
}

.advice-item {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    display: flex;
    align-items: center;
}

.advice-item img {
    margin-right: 1rem;
}
/* advice-page */

/* invest-page */

.invest-page {
    /* height: 100vh; */
    background: linear-gradient(253.64deg, #abf9a7 6.24%, #59c8f7 105.74%);
    border-radius: 350px 0px;
    overflow: hidden;
    padding-left: 3rem;
}

.invest-page h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: #000000;
}

/* invest-page */

/* buying-page */

.buying-page {
    height: 850px;
}

.buying-page h1 {
    margin-top: 4rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: #000000;
    text-align: center;
}

.buying-page p {
    padding-left: 3rem;
    padding-right: 3rem;
}

/* buying-page */

/* newsletter */

.newsletter {
    padding-top: 0.5rem;
    background-color: #503bda;
    height: 300px;
    border-radius: 350px 0px 0px 0px;
}

.newsletter .title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #ffffff;
}

.newsletter__container-input {
    width: 40%;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
}

.newsletter img {
    margin-right: 2rem;
}

.newsletter input {
    width: 100%;
    margin-right: 1rem;
    border: 0;
    border-radius: 10px;
}
/* newsletter */



.container-absolute-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: end;
}