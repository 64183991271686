.btn-connection {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
    background: linear-gradient(90.27deg, #59c8f7 47.76%, #abf9a7 99.85%);
    border-radius: 5px;
    border: none;
    width: 124px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
}

.btn-contact {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #797979;
    border: 1px solid #797979;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
}

.nav-mobile {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(238, 238, 238, 0.8);
}

.nav-mobile-container {
    padding: 1rem;
    background-color: white;
    height: 150px;
    flex-direction: column;
    display: flex;
    justify-content: space-around;
}

.container-img-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container-nav-close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 1rem;
}