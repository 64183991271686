.custom-btn {
    background: linear-gradient(89.93deg, #59c8f7 36.25%, #abf9a7 99.92%);
    border-radius: 7px;
    border: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
}

.custom-btn p {
    margin: 0!important;
    color: #ffffff!important;
    font-weight: bold!important;
}

.custom-btn-black {
    background: black;
    border-radius: 7px;
    border: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    margin-top: 3rem;
}

.custom-btn-black p {
    margin: 0!important;
    background: -webkit-linear-gradient(89.93deg, #59c8f7 36.25%, #abf9a7 99.92%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
