.navbar {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    padding-top: 1.5rem;
}

.navbar span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #000000;
    cursor: pointer;
}

.navbar span:hover {
    text-decoration: underline;
}

a {
    text-decoration: none!important;
}