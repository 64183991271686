.footer {
    height: 270px;
}

.footer p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: white;
    text-align: center;
}

.icon-footer {
    margin-right: 0.3rem;
    margin-left: 0.3rem;
    cursor: pointer;
}
